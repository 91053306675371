import type { FieldType } from 'tpk-website/enums/field';
import type { ServiceType } from 'tpk-website/enums/service';
import { caseStudyTechnocite } from './case-studies/technocite';
import { caseStudyAbex } from './case-studies/abex';
import { caseStudyBlueWhite } from './case-studies/blue-white';
import { caseStudyCrehopa } from './case-studies/crehopa';
import { caseStudyMediacio } from './case-studies/mediacio';

export interface CaseStudySignature {
  slug: string;
  name: string;
  projectLink: string;
  fields: FieldType[];
  services: ServiceType[];
  heroUrl: string;
  description: string;
  backgroundImageUrl: string;
  thumbnail: string;
  logo: string;
  about: object[];
  goal: object[];
  challenge: object[];
  production: object[];
  solution: object[];
  feedback: object[];
  conclusion: object[];
}

export const caseStudies: CaseStudySignature[] = [
  caseStudyTechnocite,
  caseStudyAbex,
  caseStudyBlueWhite,
  caseStudyCrehopa,
  caseStudyMediacio,
];
