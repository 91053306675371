import { FieldType } from 'tpk-website/enums/field';
import { ServiceType } from 'tpk-website/enums/service';
import type { CaseStudySignature } from '../case-studies';

export const caseStudyMediacio: CaseStudySignature = {
  slug: 'mediacio',
  name: 'mediacio',
  projectLink: 'https://www.mediacio.be',
  fields: [FieldType.Public],
  services: [ServiceType.WebApp],
  thumbnail: '/assets/images/case-study/mediacio/mediacio.png',
  heroUrl: '/assets/images/case-study/mediacio/bg-mediacio.png',
  logo: '/assets/images/case-study/mediacio/logo-mediacio.svg',
  backgroundImageUrl: '/assets/images/case-study/mediacio/bg-mediacio-1.png',
  description:
    'Le projet Mediacio vise à fournir un intranet sécurisé pour la gestion et le suivi des dossiers d’usagers en médiation de dettes. Cet outil est destiné à centraliser l’ensemble des informations et documents liés aux créances, médiateurs, courriers, et tâches, facilitant ainsi la gestion quotidienne des organismes impliqués, tels que les CPAS, avocats, ASBL, et autres organismes de médiation.',

  goal: [
    {
      text: ` 
      <h3>Faciliter la médiation de dettes</h3>
     <p>L'outil <strong>Mediacio</strong> a été conçu pour <strong>centraliser</strong> la <strong>gestion</strong> des dossiers de <strong>médiation de dettes</strong>, en regroupant <strong>efficacement</strong> les courriers, créances, médiateurs et tâches associées. En <strong>automatisant</strong> des <strong>tâches</strong> essentielles comme l'envoi d'emails et de SMS pour les rappels et le suivi, il <strong>permet</strong> aux utilisateurs de se <strong>concentrer</strong> sur des <strong>missions</strong> à plus forte valeur ajoutée. De plus, Mediacio <strong>garantit</strong> la <strong>sécurité</strong> des </strong>données</strong> grâce à une architecture "security by design", protégeant les <strong>informations sensibles</strong> tout en offrant un accès restreint aux utilisateurs autorisés.</p>

<p>Un des points forts de <strong>Mediacio</strong> est son <strong>moteur de recherche</strong> multicritères <strong>performant</strong>, qui facilite l’accès rapide aux différentes ressources, <strong>améliorant</strong> ainsi l’<strong>efficacité</strong> des utilisateurs. La <strong>génération automatique de documents</strong> permet de créer des correspondances administratives cohérentes à partir des informations des dossiers, <strong>simplifiant</strong> les <strong>échanges</strong> avec les parties prenantes et assurant la cohérence des <strong>processus</strong>.</p>

<p>Enfin, <strong>Mediacio</strong> propose un tableau de bord intuitif qui offre une <strong>vue d’ensemble</strong> des tâches en cours et des <strong>statistiques</strong> d’activité. Les utilisateurs peuvent <strong>suivre</strong> leurs performances via des <strong>rapports</strong> générés automatiquement, garantissant ainsi une <strong>gestion</strong> proactive et<strong>efficace</strong> des dossiers. En combinant centralisation, automatisation et sécurité, <strong>Mediacio</strong> se positionne comme un<strong> outil indispensable</strong> pour les organismes de <strong>médiation de dettes</strong>.</p>
      `,
    },
  ],
  challenge: [
    {
      text: ` 
      <p>La gestion <strong>sécurisée</strong> des données sensibles est une <strong>priorité</strong> dans le contexte de la <strong>médiation de dettes</strong>. Il est <strong>essentiel</strong> de <strong>protéger</strong> les <strong>informations</strong> personnelles et financières des usagers, créanciers et autres parties prenantes, tout en <strong>respectant</strong> scrupuleusement les réglementations comme le <strong>GDPR</strong>. Assurer la <strong>confidentialité</strong> et la sécurité des <strong>données</strong> permet de maintenir un environnement de <strong>confiance</strong> entre tous les intervenants, garantissant ainsi une <strong>gestion</strong> des dossiers <strong>efficace</strong> et en toute <strong>conformité</strong>.</p>

<p>Malgré la <strong>complexité</strong> inhérente aux processus de <strong>médiation</strong>, impliquant <strong>plusieurs acteurs</strong> tels que les usagers, créanciers et médiateurs, la <strong>simplicité</strong> d’utilisation de la <strong>solution</strong> reste une <strong>priorité</strong>. L'intégration de <strong>workflows</strong> complexes est associée à une <strong>automatisation</strong> des tâches, comme l'envoi de rappels et d'emails, sans compromettre la <strong>précision</strong> ni la personnalisation du <strong>suivi</strong> des dossiers. La <strong>flexibilité</strong> de l’outil permet également de s’adapter aux <strong>besoins</strong> spécifiques des différents <strong>utilisateurs</strong>, qu'ils soient CPAS, avocats ou autres organismes.</p>

<p>La <strong>performance</strong> du moteur de <strong>recherche</strong> joue un rôle clé pour garantir une <strong>efficacité</strong> maximale. Il doit <strong>permettre</strong> aux utilisateurs de <strong>retrouver</strong> rapidement les <strong>ressources</strong> nécessaires, même dans des environnements où des <strong>milliers de dossiers</strong> sont actifs. Le <strong>tableau de bord</strong>, quant à lui, offre une <strong>vue</strong> claire et précise des <strong>tâches</strong> en cours, permettant de gérer la <strong>charge</strong> de travail de manière <strong>optimale</strong>, tout en maximisant la <strong>productivité</strong> et en évitant la surcharge.</p>
    `,
      image: '/assets/images/case-study/mediacio/search.webp',
    },
  ],
  solution: [
    {
      text: ` 
      <p>Dès sa conception, l'intranet a été pensé pour garantir une <strong>sécurisation optimale des données</strong>. Les protocoles de sécurité avancés mis en place incluent l'<strong>authentification sécurisée</strong>, le <strong>cryptage des données</strong> et un <strong>accès limité</strong> aux informations sensibles. Ces mesures assurent une <strong>confidentialité maximale</strong> pour les données personnelles et financières des usagers et des créanciers, en conformité avec les normes en vigueur. Chaque utilisateur dispose d'un accès contrôlé, garantissant ainsi que seules les personnes autorisées puissent consulter et manipuler les informations critiques.</p>
     
      `,
    },
    {
      text: ` 
 

<p>L'une des fonctionnalités essentielles de cet outil est l'<strong>automatisation des tâches récurrentes</strong>, en particulier l’envoi automatique d’emails et de SMS. Ces notifications permettent de rappeler aux usagers et aux créanciers les <strong>échéances importantes</strong>, comme les paiements ou les rendez-vous. En automatisant ces tâches, l'outil allège considérablement la <strong>charge de travail</strong> des utilisateurs tout en maintenant un <strong>suivi rigoureux</strong> des dossiers. Cette automatisation permet également d'<strong>améliorer la réactivité</strong> et la <strong>communication</strong> entre les parties concernées.</p>

<p>Pour faciliter la gestion des dossiers, un <strong>moteur de recherche avancé</strong> a été intégré à la plateforme. Ce moteur permet une <strong>recherche multicritères</strong>, offrant aux utilisateurs la possibilité de retrouver rapidement des <strong>ressources</strong> telles que les usagers, créanciers, documents ou tâches. Grâce à cette fonctionnalité, les informations sont facilement accessibles, ce qui optimise le <strong>temps de travail</strong> et assure une <strong>gestion plus efficace</strong> des dossiers, même dans des environnements où les volumes de données peuvent être importants.</p>

<p>De plus, la <strong>génération automatique de documents</strong> est une fonctionnalité clé de l'outil. Grâce à un module intégré, les utilisateurs peuvent <strong>créer rapidement</strong> des lettres, courriers de suivi ou contrats, directement à partir

      `,
    },
    {
      text: ` 
      <p>
      Grâce à cette <strong>solution digitale</strong>, mediacio a pu <strong>améliorer</strong> significativement <strong>l'expérience client</strong>, réduire les temps de <strong>recherche</strong> et <strong>optimiser</strong> ses <strong>processus</strong>. Notre équipe est fière d'avoir pu apporter une<strong> valeur ajoutée</strong> à cette entreprise en lui offrant une <strong>solution digitale innovante</strong> et <strong>efficace</strong>.

      </p>
  `,
    },
  ],
  production: [
    {
      image: '/assets/images/case-study/mediacio/rapports.webp',
      caption: 'Rapports et dossiers mediacio',
    },

    {
      image: '/assets/images/case-study/mediacio/agenda.webp',
      caption: "Angenda et modèles d'emails mediacio",
    },
  ],
  feedback: [],
  conclusion: [
    {
      text: ` <p>Si l'outil mediacio inspire votre projet digital, prenons le temps de discuter de vos ambitions ! Si vous envisagez une refonte de votre site web, l'intégration d'un webshop ou la mise en place d'un backoffice de gestion, contactez-nous dès maintenant <a href=”mailto:info@triptyk.eu”>par email</a> ou <a href="tel:03227353710">téléphone</a>."
    </p>
    <p>'Recevez un devis sur mesure et entièrement gratuit, et ensemble, donnons vie à votre projet digital.'</p>
    `,
    },
  ],

  about: [],
};
